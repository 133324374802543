import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

Vue.config.productionTip = false;

import axios from 'axios';



import VueExcelXlsx from "vue-excel-xlsx";
Vue.use(VueExcelXlsx);

import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';

Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);


import moment from 'moment'

Vue.prototype.moment = moment;

// api servidor local
//axios.defaults.baseURL='http://localhost:3000/api/'

import DatetimePicker from 'vuetify-datetime-picker'
Vue.use(DatetimePicker);

// api servidor sin https  sv
//axios.defaults.baseURL='http://solucionesdisatelsv.com:3000/api/'

// api servidor SV
axios.defaults.baseURL='http://157.245.251.42:3000/api/'

// api servidor nicaragua
//axios.defaults.baseURL='http://134.122.2.58:3000/api/'



import VueApexCharts from 'vue-apexcharts';
Vue.use(VueApexCharts);
Vue.component('apexchart', VueApexCharts);





new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
