import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store';

Vue.use(VueRouter)

const routes = [

  {
    path: '/',
    name: 'inicio',
    component: () => import(/* webpackChunkName: "vehiculos" */ '../views/Inicio.vue'),
    meta:{
      libre:true
    }
  },

  {
    path: '/home',
    name: 'home',
    component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue'),
    meta:{
      administrador:true,
      usuario_ATC:true
    }
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    meta:{
      administrador:true,
      usuario_ATC:true,
    }
  },

  {
    path: '/clientes',
    name: 'clientes',
    component: () => import(/* webpackChunkName: "Clientes" */ '../views/SaludFlota/VistaClientes.vue'),
    meta:{
      administrador:true,
      usuario_ATC:true,
      programacion:true
    }
  }, 

  {
    path: '/vehiculos',
    name: 'vehiculos',
    component: () => import(/* webpackChunkName: "vehiculos" */ '../views/SaludFlota/Vehiculos.vue'),
    meta:{
      administrador:true,
      usuario_ATC:true,
    }
  },

  {
    path: '/historico',
    name: 'historico',
    component: () => import(/* webpackChunkName: "Historico" */ '../views/SaludFlota/Historico.vue'),
    meta:{
      administrador:true,
      usuario_ATC:true,
    }
  },

  {
    path: '/estados',
    name: 'estados',
    component: () => import(/* webpackChunkName: "Estados" */ '../views/SaludFlota/Estados.vue'),
    meta:{
      administrador:true,
      usuario_ATC:true,
    }
  },

 
  {
    path: '/ejecutivos',
    name: 'ejecutivos',
    component: () => import(/* webpackChunkName: "Ejecutivos" */ '../views/Ejecutivos.vue'),
    meta:{
      administrador:true,
      usuario_ATC:true,
    }
  },
 

 
 

  {
    path: '/configuracion',
    name: 'configuracion',
    component: () => import(/* webpackChunkName: "vehiculos" */ '../views/Configuraciones.vue'),
    meta:{
      administrador:true,
      usuario_ATC:true,
    }
  },



  {
    path: '/bitacora',
    name: 'bitacora',
    component: () => import(/* webpackChunkName: "bitacora visor" */ '../views/Monitoreo/Bitacora.vue'),
    meta:{
      administrador:true,
      usuario_ATC:false,
      operator:true,
    }
  },

  {
    path: '/tickets',
    name: 'tickets',
    component: () => import(/* webpackChunkName: "tickets" */ '../views/Monitoreo/Tickets.vue'),
    meta:{
      administrador:true,
      usuario_ATC:false,
      operator:true
    }
  },


  {
    path: '/graficaoperador',
    name: 'graficaoperador',
    component: () => import(/* webpackChunkName: "bitacora visor" */ '../views/Monitoreo/GraficaOperador.vue'),
    meta:{
      administrador:true,
      usuario_ATC:false,
      operator:true,
    }
  },


  {
    path: '/monitoreo',
    name: 'monitoreo',
    component: () => import(/* webpackChunkName: "vehiculos" */ '../views/Monitoreo/Monitoreo.vue'),
    meta:{
      administrador:true,
      usuario_ATC:false,
      operator:true,
    }
  },

  {
    path: '/bloqueos',
    name: 'bloqueos',
    component: () => import(/* webpackChunkName: "vehiculos" */ '../views/Monitoreo/Bloqueos.vue'),
    meta:{
      administrador:true,
      usuario_ATC:false,
      operator:true,
    }
  },

  {
    path: '/visorclientes',
    name: 'visorclientes',
    component: () => import(/* webpackChunkName: "Clientes" */ '../views/Monitoreo/VisorClientes.vue'),
    meta:{
      administrador:true,
      usuario_ATC:true,
      operator:true,
    }
  }, 




  {
    path: '/historico_eventos',
    name: 'historico_eventos',
    component: () => import(/* webpackChunkName: "vehiculos" */ '../views/Monitoreo/HistoricoEventos.vue'),
    meta:{
      administrador:true,
      usuario_ATC:false,
      operator:true,
    }
  },

  
  {
    path: '/resumen',
    name: 'resumen',
    component: () => import(/* webpackChunkName: "vehiculos" */ '../views/Monitoreo/ResumenEventos.vue'),
    meta:{
      administrador:true,
      usuario_ATC:false,
      operator:true,
    }
  },

      //------------------------------------secciones solo para usuario contabilidad------------------
  {
    path: '/clientescta',
    name: 'clientescta',
    component: () => import(/* webpackChunkName: "Clientes" */ '../views/Contabilidad/ClientesCta.vue'),
    meta:{
      administrador:true,
      usuario_ATC:true,
      conta:true,
    }
  },

   {
    path: '/calendariopagos',
    name: 'calendariopagos',
    component: () => import( '../views/Contabilidad/CalendarioPromesas.vue'),
    meta:{
      administrador:true,
      usuario_ATC:true,
      conta:true,
    }
  },

  {
    path: '/historicoconta',
    name: 'historicoconta',
    component: () => import( '../views/Contabilidad/HistoricoConta.vue'),
    meta:{
      administrador:true,
      usuario_ATC:true,
      conta:true,
    }
  },


  {
    path: '/estadisticacta',
    name: 'estadisticacta',
    component: () => import( '../views/Contabilidad/EstadisticasConta.vue'),
    meta:{
      administrador:true,
      usuario_ATC:true,
      conta:true,
    }
  },


  {
    path: '/calendario',
    name: 'calendario',
    component: () => import( '../views/Programacion/Calendario.vue'),
    meta:{
      administrador:true,
      usuario_ATC:false,
      programacion:true,
    }
  },



  {
    path: '/bitacoratecnicos',
    name: 'bitacoratecnicos',
    component: () => import( '../views/Tecnico/BitacoraTecnicos.vue'),
    meta:{
      administrador:false,
      usuario_ATC:false,
      programacion:false,
      tecnico:true
    }
  },



  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
    meta:{
      libre:true
    }
  },





]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});




router.beforeEach((to, from, next)=>{



  //si alguien recarga la url debemos tomar ese token e ingresarlo al state

const token_storage=localStorage.getItem('token');

if(token_storage!=null){


  if(!store.state.usuario){

    console.log('token encontrado');
    console.log(token_storage);
    store.dispatch("guardarToken", token_storage);
  }

}




  if(to.matched.some(record=>record.meta.libre)){
    next();


    //validamos rutas solo administrador
  }else if( store.state.usuario && store.state.usuario.rol=='ADMIN'){

    if(to.matched.some(record=>record.meta.administrador)){
      next();
    }

    //ruta para usuarios comunes
  }else if(store.state.usuario && store.state.usuario.rol=='ATC'){

    if(to.matched.some(record=>record.meta.usuario_ATC)){
          next();
        }

    //si no tiene ningun permiso salir
  }else if(store.state.usuario && store.state.usuario.rol=='OPERATOR'){

    if(to.matched.some(record=>record.meta.operator)){
          next();
        }

    //si no tiene ningun permiso salir
  }else if(store.state.usuario && store.state.usuario.rol=='CONTA'){

    if(to.matched.some(record=>record.meta.conta)){
          next();
        }

    //si no tiene ningun permiso salir
  }else if(store.state.usuario && store.state.usuario.rol=='PROGRAMACION'){

    if(to.matched.some(record=>record.meta.programacion)){
          next();
        }

    //si no tiene ningun permiso salir
  }else if(store.state.usuario && store.state.usuario.rol=='TECNICO'){

    if(to.matched.some(record=>record.meta.tecnico)){
          next();
        }

    //si no tiene ningun permiso salir   
  }else{
    next({name:'login'});

    
    console.log('entro en ruta libre');

  }

});




export default router
