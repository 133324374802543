<template>

<div id="app">
  <v-app id="inspire">
    <v-app id="inspire">
      <v-navigation-drawer  v-model="drawer"  :clipped="$vuetify.breakpoint.lgAndUp" v-if="logeado==true"  app>
       
       
       <!--- detalle de menu cualquier usuario-->
        <v-list dense v-if="((logeado==true) && (esUsuarioComun||esAdminstrador))" >
          <template v-for="item in items">
            <v-row v-if="item.heading" :key="item.heading" align="center">

              <v-col cols="6">
                <v-subheader v-if="item.heading">
                  {{ item.heading }}
                </v-subheader>
              </v-col>
             
            </v-row>
            <!--v-list-group
              v-else-if="item.children" :key="item.text"
              v-model="item.model" :prepend-icon="item.model ? item.icon : item['icon-alt']"
              append-icon=""
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ item.text }}
                  </v-list-item-title>
                </v-list-item-content>
              </template>
              <v-list-item  v-for="(child, i) in item.children" :key="i" link>
                <v-list-item-action v-if="child.icon">
                  <v-icon>{{ child.icon }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ child.text }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group-->

            <v-list-item v-if="((item.protegido==false || esAdminstrador==true) && (!item.children))" :key="item.text"  :to="{name:item.to}"  link>
              <v-list-item-action>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.text }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>

<!--- detalle de menu  Operador-->
         <v-list dense v-if="((logeado==true) && (esOperador))" >
          <template v-for="item in itemsOperador">
            <v-row v-if="item.heading" :key="item.heading" align="center">

              <v-col cols="6">
                <v-subheader v-if="item.heading">
                  {{ item.heading }}
                </v-subheader>
              </v-col>
             
            </v-row>
            <!--v-list-group
              v-else-if="item.children" :key="item.text"
              v-model="item.model" :prepend-icon="item.model ? item.icon : item['icon-alt']"
              append-icon=""
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ item.text }}
                  </v-list-item-title>
                </v-list-item-content>
              </template>
              <v-list-item  v-for="(child, i) in item.children" :key="i" link>
                <v-list-item-action v-if="child.icon">
                  <v-icon>{{ child.icon }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ child.text }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group-->

            <v-list-item v-if="((item.protegido==false || esAdminstrador==true) && (!item.children))" :key="item.text"  :to="{name:item.to}"  link>
              <v-list-item-action>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.text }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>

      
<!--- detalle de menu  esContabilidad-->

  <v-list dense v-if="((logeado==true) && (esContabilidad))" >
          <template v-for="item in itemsContabilidad">
            <v-row v-if="item.heading" :key="item.heading" align="center">

              <v-col cols="6">
                <v-subheader v-if="item.heading">
                  {{ item.heading }}
                </v-subheader>
              </v-col>
             
            </v-row>
            <!--v-list-group
              v-else-if="item.children" :key="item.text"
              v-model="item.model" :prepend-icon="item.model ? item.icon : item['icon-alt']"
              append-icon=""
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ item.text }}
                  </v-list-item-title>
                </v-list-item-content>
              </template>
              <v-list-item  v-for="(child, i) in item.children" :key="i" link>
                <v-list-item-action v-if="child.icon">
                  <v-icon>{{ child.icon }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ child.text }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group-->

            <v-list-item v-if="((item.protegido==false || esAdminstrador==true) && (!item.children))" :key="item.text"  :to="{name:item.to}"  link>
              <v-list-item-action>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.text }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>


      <!-- esProgramacion  ---->  
  <v-list dense v-if="((logeado==true) && (esProgramacion))" >
          <template v-for="item in itemsProgramacion">
            <v-row v-if="item.heading" :key="item.heading" align="center">

              <v-col cols="6">
                <v-subheader v-if="item.heading">
                  {{ item.heading }}
                </v-subheader>
              </v-col>
             
            </v-row>
        
            <v-list-item v-if="((item.protegido==false || esAdminstrador==true) && (!item.children))" :key="item.text"  :to="{name:item.to}"  link>
              <v-list-item-action>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.text }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>




        


            <!-- esProgramacion  ---->  
  <v-list dense v-if="((logeado==true) && (esTecnico))" >
          <template v-for="item in itemsTecnicos">
            <v-row v-if="item.heading" :key="item.heading" align="center">

              <v-col cols="6">
                <v-subheader v-if="item.heading">
                  {{ item.heading }}
                </v-subheader>
              </v-col>
             
            </v-row>
        
            <v-list-item v-if="((item.protegido==false || esAdminstrador==true) && (!item.children))" :key="item.text"  :to="{name:item.to}"  link>
              <v-list-item-action>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.text }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>


      </v-navigation-drawer>
  
      <v-app-bar :clipped-left="$vuetify.breakpoint.lgAndUp" app  color="disatel" dark>
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
       
        <v-toolbar-title style="width: 300px" class="ml-0 pl-4">

          <span class="hidden-sm-and-down" v-if="esUsuarioComun">Macro salud Flota</span>
          <span class="hidden-sm-and-down" v-else>Monitoreo Disatel</span>
         
           <v-btn icon large>
         
          <v-avatar size="32px" item>
              <v-img
                src="./assets/Disatel.jpg"
                alt="icono"
              ></v-img>
         </v-avatar>

        </v-btn>


        </v-toolbar-title>
        
        <v-text-field flat solo-inverted hide-details prepend-inner-icon="search"  label="Search" class="hidden-sm-and-down"></v-text-field>
        <v-spacer></v-spacer>


        <span v-if= "(logeado!=null && esOperador)">{{ this.usuario.nombre_ejecutivo }}</span>


        <v-btn icon large v-if="logeado!=null" @click="salir()">
         <v-icon>exit_to_app</v-icon>
        </v-btn>
        <v-btn v-else :to="{name:'login'}" color="success">
          <v-icon>login</v-icon>
        </v-btn>

      </v-app-bar>

        <v-main>
         <router-view/>
       </v-main>


          <v-dialog
                   v-model="loading.estado"
                    hide-overlay
                     persistent
                     width="300">
                        <v-card
                          color="red"
                          dark
                        >
                          <v-card-text>
                          {{loading.titulo}}
                            <v-progress-linear
                              indeterminate
                              color="white"
                              class="mb-0"
                            ></v-progress-linear>
                          </v-card-text>
                        </v-card>
                </v-dialog>

  <v-footer absolute v-if="(logeado && !esOperador)" color="opcion1" class="red--text">
   
   <span class="caption">Power BY Disatel NI</span>
   <v-divider></v-divider>

   <span>{{ this.usuario.nombre_ejecutivo }}</span>
  </v-footer>

    </v-app>


   


  </v-app>



</div>

  
</template>

<script>

import { mapState,mapMutations } from "vuex";

export default {
  name: 'App',

  data: () => ({
    
    dialog: false,
    drawer: null,
    items: [
      { icon: 'home', text: 'Home', to:'home',protegido:false },
      { icon: 'contacts', text: 'Clientes', to:'clientes',protegido:false },
      { icon: 'commute', text: 'Vehiculos', to:'vehiculos',protegido:false},
      { icon: 'history', text: 'Historicos', to:'historico',protegido:false},
      { icon: 'label', text: 'Estados', to:'estados',protegido:false,operadores:false},
      { icon: 'account_circle', text: 'Ejecutivos', to:'ejecutivos',protegido:true },
      { icon: 'settings', text: 'configuracion', to:'configuracion',protegido:true},
  
    ],

    itemsOperador:[

        { icon: 'bus_alert', text: 'monitoreo', to:'monitoreo',protegido:false},
        { icon: 'history', text: 'Historicos', to:'historico_eventos',protegido:false},
        { icon: 'confirmation_number', text: 'Tickets', to:'tickets',protegido:false},
        

        { icon: 'summarize', text: 'Resumen', to:'resumen',protegido:false},
        { icon: 'no_transfer', text: 'Bloqueos', to:'bloqueos',protegido:false},

         { icon: 'contacts', text: 'Clientes', to:'visorclientes',protegido:false },
         { icon: 'electric_rickshaw', text: 'Bitacora', to:'bitacora',protegido:false},
         { icon: 'analytics', text: 'analisis', to:'graficaoperador',protegido:false},

    ],


     itemsContabilidad:[

       { icon: 'contacts', text: 'Clientes', to:'clientescta',protegido:false },
       { icon: 'today', text: 'Calendario', to:'calendariopagos',protegido:false },
       { icon: 'history', text:'Historico', to:'historicoconta', protegido:false},
       { icon: 'analytics', text: 'analisis', to:'estadisticacta',protegido:false},
      
     ],

      itemsProgramacion:[
         { icon: 'today', text: 'Calendario', to:'calendario',protegido:false },
         { icon: 'contacts', text: 'Clientes', to:'clientes',protegido:false },
    
      
     ],

     itemsTecnicos:[
         { icon: 'electric_rickshaw', text: 'bitacoratecnicos', to:'bitacoratecnicos',protegido:false },
      
     ],


  }),
  computed:{
      ...mapState(['loading','usuario']),

       logeado(){

        let token_storage=localStorage.getItem('token');

        if(token_storage){
          console.log('se detecto cambio en storage:'+this.$store.state.usuario);
          return true;
        }else{

           console.log('se detecto cambio en storage:'+this.$store.state.usuario+' --->');
          return false;
        }
       
         // return this.$store.state.usuario;
      }, 
      
      esAdminstrador(){
          return this.$store.state.usuario && this.$store.state.usuario.rol=='ADMIN';
      }, 
      
      esUsuarioComun(){
          return this.$store.state.usuario && this.$store.state.usuario.rol=='ATC';
      }, 
      esOperador(){
        if(this.$store.state.usuario){
              return this.$store.state.usuario && this.$store.state.usuario.rol=='OPERATOR';
        }else{
          return false;
        }
         
      },

      esContabilidad(){
          if(this.$store.state.usuario){
                return this.$store.state.usuario && this.$store.state.usuario.rol=='CONTA';
          }else{
            return false;
          }

      },

         esProgramacion(){
          if(this.$store.state.usuario){
                return this.$store.state.usuario && this.$store.state.usuario.rol=='PROGRAMACION';
          }else{
            return false;
          }

      },



      esTecnico(){
          if(this.$store.state.usuario){
                return this.$store.state.usuario && this.$store.state.usuario.rol=='TECNICO';
          }else{
            return false;
          }

      },



},
 methods:{
      ...mapMutations(['mostrarLoading','ocultarLoading']),

       salir(){
          this.drawer=false;
          this.$store.dispatch("salir");
      }

  }

}
</script>
