import Vue from 'vue'
import Vuex from 'vuex'

import decode from 'jwt-decode';
import router from '../router';
Vue.use(Vuex);


export default new Vuex.Store({
  state: {

    token:null,
    usuario:null,


    loading:{
      titulo:'',
      estado:false,
      color:'primary'
    },


  },
  mutations: {

    mostrarLoading(state, payload){
      state.loading.titulo = payload.titulo
      state.loading.estado = true
      state.loading.color=payload.color
    },
    ocultarLoading(state){
     state.loading.estado=false
    },

      //mofican el estado del state->token
      setToken(state, token){
        state.token=token;
       
      },

        //modifica el estado del state->usuario
    setUsuario(state, payload){
      if(payload!=null && payload!=''){
        let EjecutivoDB=decode(payload);
        state.usuario=EjecutivoDB.data;

        localStorage.setItem("usuario", EjecutivoDB);
        console.log(state.usuario);
      }
  }




    
  },
  actions: {


       //llamdo al momento de logear en login
       guardarToken({commit}, token){
        commit("setUsuario", token); //ejecuta la mutacion que establece valor de usuario
        localStorage.setItem("token", token);
        commit("setToken", token);      //ejecuta la mutacion que establece valor de token


     
        if(this.state.usuario.rol=="OPERATOR"){
          console.log('si es OPERATOR');

            //redirecciona al guardar token
            router.push({name:'monitoreo'});

        }else if(this.state.usuario.rol=="CONTA"){

            //redirecciona al guardar token
            router.push({name:'clientescta'});

        }else if(this.state.usuario.rol=="TECNICO"){

          //redirecciona al guardar token
          router.push({name:'bitacoratecnicos'});

      }else{
              //redirecciona al guardar token
              router.push({name:'home'});
        }
       
      
       
      },
  
    
  
        //llamdo al ingreso para omitir login
      autoLogin({commit}){
        let token=localStorage.getItem("token");
        if(token!=null){
          commit("setToken", token);     
          commit("setUsuario", token); 
          
          router.push({name:'vehiculos'});
        }
  
        
  
      },
  
      //borra los datos de local storage y asigna null a token y usuario
      salir({commit}){
  
        localStorage.removeItem("token");
  
        commit("setToken", null);     
        commit("setUsuario", null);
  
       
        router.push({name:'login'});
  
      }

  },
  modules: {
  }
})
